html,
body,
#root,
.App {
   height: 100%;
}

body {
   margin: 0px;
   padding: 0px;
}
